import React from "react";

class Nav extends React.Component {
    render() {
        return (
            <>
            <nav className="navbar navbar-dark bg-dark">
            <div className="container">
            <a className="navbar-brand" href="#navbar">
                <img src="http://via.placeholder.com/20x20
" alt="" width="30" height="24" className="d-inline-block align-text-top"/>
                RICHCREEK
                </a>
            </div>
            </nav>
            </>
        )
    }
}

export default Nav